import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>Messina Messenger</h1>

                <h2>Suspects Suspected</h2>

                <p>In an animated interview earlier this morning, Dogberry announced that he believes he knows who captured the images of Margaret in a compromising position. He revealed that he is tracking the men today, but declined to release the names of the suspects.
“I can assuage you that I’m hot on their tails,” Dogberry declared. The Messenger feels assured that Dogberry will be more forthcoming with information once his pursuit of the suspects’ trails has come to a close. At which point, we hope to discover that the suspects do not, in actuality, have tails.
</p>
            </div>
        </Layout>
    )
}